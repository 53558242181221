import DatePicker from 'vue2-datepicker';
export default {
    name: 'addgstrTwoAdownload',
    components: {
        DatePicker
    },
    props: {},
    data() {
        return {
            searchForm: {
                year_from: null,
                month_from: null,
                year_to: null,
                month_to: null
            }
        }
    },
    mounted() { },
    methods: {
    },
    beforeDestroy() {
        this.unsubscribe();
    }
};
